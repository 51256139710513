@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Roboto&display=swap');

@font-face {
  font-family: "Dancing_Script";
  weight: '400';
  src: url("../public/fonts/DancingScript-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Roboto";
  weight: '400';
  src: url("../public/fonts/Roboto-Regular.ttf");
}

body {
  color: #333620;
  background: #F1F0EE;
  overflow-x: hidden;
  touch-action: pan-y;
}

h1 {
  color: #2E3213;
  font-size: 42px;
  line-height: 120%;
  letter-spacing: -0.025em;
}

h2 {
  color: #2E3213;
  font-size: 20px;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: -0.025em;
}

h3 {
  color: #2E3213;
  font-size: 18px;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: -0.025em;
}

a {
  cursor: pointer;
}

span, p, button, a {
  line-height: 160%;
  letter-spacing: -0.025em;
}

li a {
  display: block;
}

.HEADER-LINK {
  box-shadow: inset 0 0 0 0 #2E3213;
  color: #2E3213;
	padding: 0 .25rem;
	margin: 0 -.25rem;
  transition: color .8s ease-in-out, box-shadow .8s ease-in-out;
}
.HEADER-LINK:hover {
  color: #F1F0EE;
  box-shadow: inset 200px 0 0 0 #2E3213;;
}

.swiper-container {
  height: 400px;
}

.swiper-container-vertical .swiper-scrollbar {
  display: none;
}

.hideMenuNav {
  display: none;
}

.showMenuNav {
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: #F1F0EE;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.bg-gradient-center {
    background-image: radial-gradient(ellipse at center, #543046, #1E1E1E);
    background-size: 70% 70%;
  }

@media (max-height: 716px) {
  .HIDDEN-HEIGHT {
    display: none;
  }
}
